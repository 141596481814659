<!--Vista para recuperar contraseña. Se ingresa el correo de usuario y le llega un correo para recuperar la contraseña-->
<template>
  <v-container
    fluid
    class="
      pb-0
      px-0
      password-recover-form
      d-flex
      align-space-between
      flex-column
    "
  >
    <v-spacer></v-spacer>
    <v-form v-model="validPasswordRecover" ref="passwordRecoverForm">
      <v-row
        justify="center"
        :class="$vuetify.breakpoint.smAndDown ? 'mt-7' : ''"
      >
        <v-col cols="10" sm="6" lg="4" xl="3">
          <v-row>
            <v-col cols="12">
              <p class="display-1 text-center font-weight-bold">
                Ingrese su correo
              </p>
            </v-col>
            <v-col cols="12" class="py-0">
              <v-text-field
                v-model="userEmail"
                elevation="0"
                label="Correo electrónico"
                type="email"
                filled
                rounded
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-btn
                color="accent"
                rounded
                block
                @click="_passwordRecover()"
                :loading="loadingButton"
                :disabled="userEmail == '' || !validPasswordRecover"
                ><span class="font-weight-black black--text"
                  >Aceptar</span>
              </v-btn>
            </v-col>
            <v-col cols="12" class="text-end">
              <p class="subtitle-1 mb-0 font-weight-bold">
                ¿Ya tienes una cuenta?
              </p>
              <a
                class="secondary--text font-weight-bold subtitle-1 mb-0"
                @click="_login()"
                >Iniciar sesión</a
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <v-spacer></v-spacer>
  </v-container>
</template>
<script>
import { mapMutations, mapActions } from "vuex";
import { validations } from "@/share/validations.js";
export default {
  data() {
    return {
      validPasswordRecover: false,
      userEmail: '',
      loadingButton: false,
      emailRules: validations.emailValidation({
        required: true,
      }),
    };
  },
  methods: {
    ...mapActions("authentication", ["passwordRecover"]),
    ...mapActions("user", ["login"]),
    ...mapMutations("notification", ["show"]),

    validatePasswordRecover() {
      if (this.$refs.passwordRecoverForm.validate()) {
        this.snackbar = true;
        return true;
      }
      return false;
    },
    async _passwordRecover() {
      if (this.validatePasswordRecover()) {
        try {
          this.loadingButton = true;
          let response = await this.passwordRecover(this.userEmail);
          if (response.status == true) {
            this.show({
              text: "Se ha enviado un correo de recuperación",
              color: "primary"
            });
            this.$refs.passwordRecoverForm.reset();
          } else {
            this.show({
              text: "El usuario no existe, favor de contactar a tu organización.",
              color: "error",
              time: 2000,
            });
          }
          this.loadingButton = false;
        } catch (err) {
          console.log(err);
        }
      }
    },

    _login() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.password-recover-form {
  min-height: 70vh;
}
</style>